<template>
  <div class="my-14 py-2" id="projects">
    <div class="text-center pb-5">
      <h2 class="font-weight-bold mb-3">
        <v-icon x-large color="orange">mdi-lightbulb</v-icon>
        Projets personnels
      </h2>
      <p class="subheading">
        Liste de mes projets personnels en cours de réalisation
      </p>
    </div>

    <v-row justify="center">
      <v-col cols="12" data-aos="flip-down">
        <v-card>
          <v-tabs
            v-model="tab"
            dark
            :background-color="currentColor"
            show-arrows
            next-icon="mdi-arrow-right"
            prev-icon="mdi-arrow-left"
            centered
            center-active
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="(project, key) in mainProjects" :key="key">{{
              project.title
            }}</v-tab>
            <!-- <v-tab>Autres projets</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(project, index) in mainProjects" :key="index">
              <v-card flat>
                <v-card-title class="justify-center">
                  <h3>{{ project.title }}</h3>
                </v-card-title>
                <v-card-subtitle class="text-center">
                  {{ project.subtitle }}
                  <div>
                    <v-icon
                      class="mr-1"
                      :color="project.available ? 'green' : 'red'"
                      >mdi-circle</v-icon
                    >
                    <span
                      v-text="
                        project.available ? 'Disponible' : 'En construction'
                      "
                    ></span>
                  </div>
                </v-card-subtitle>
                <v-card-text class="py-5">
                  <v-row align="start" justify="center">
                    <v-col cols="12" md="6">
                      <div class="text-h5 black--text">Description :</div>
                      <p>{{ project.description }}</p>
                      <div class="d-none d-md-block">
                        <p>Vous pouvez y retrouver :</p>
                        <ul>
                          <li
                            v-for="(detail, key) in project.details"
                            :key="key"
                          >
                            {{ detail }}
                          </li>
                        </ul>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="5" md="6">
                      <v-list class="pt-0">
                        <div class="text-h5">Outils :</div>
                        <v-list-item
                          v-for="(tool, key) in project.tools"
                          :key="key"
                        >
                          <v-list-item-avatar rounded="0">
                            <v-img
                              contain
                              :src="require(`../assets/icons/${tool.icon}`)"
                              :alt="`Le logo de ${tool.name}`"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              :class="`text-h6 ${tool.color}--text`"
                              >{{ tool.name }}</v-list-item-title
                            >
                            <p
                              class="
                                subtitle-2
                                font-weight-light
                                d-none d-md-block
                              "
                            >
                              {{ tool.detail }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="7" md="8" class="d-none d-sm-block">
                      <Terminal :codes="project.codes" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                  <v-dialog v-model="dialog[index]">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="d-block d-md-none"
                        v-bind="attrs"
                        v-on="on"
                        :color="currentColor"
                        dark
                        >Plus d'informations...</v-btn
                      >
                    </template>
                    <v-card class="py-5">
                      <v-card-title class="justify-center">
                        {{ project.title }}
                      </v-card-title>
                      <v-card-subtitle class="text-center">
                        {{ project.subtitle }}
                        <div>
                          <v-icon
                            class="mr-2"
                            :color="project.available ? 'green' : 'red'"
                            >mdi-circle</v-icon
                          >
                          <span
                            v-text="
                              project.available
                                ? 'Disponible'
                                : 'En construction'
                            "
                          ></span>
                        </div>
                      </v-card-subtitle>
                      <v-card-text>
                        <div class="text-h5 black--text">Description :</div>
                        <p>{{ project.description }}</p>
                        <div>
                          <p>Vous pouvez y retrouver :</p>
                          <ul>
                            <li
                              v-for="(detail, key) in project.details"
                              :key="key"
                            >
                              {{ detail }}
                            </li>
                          </ul>
                        </div>
                        <v-list>
                          <div class="text-h5">Outils :</div>
                          <v-list-item
                            v-for="(tool, key) in project.tools"
                            :key="key"
                          >
                            <v-list-item-avatar rounded="0">
                              <v-img
                                contain
                                :src="require(`../assets/icons/${tool.icon}`)"
                                :alt="`Le logo de ${tool.name}`"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                :class="`text-h6 ${tool.color}--text`"
                                >{{ tool.name }}</v-list-item-title
                              >
                              <p class="subtitle-2 font-weight-light">
                                {{ tool.detail }}
                              </p>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <Terminal :codes="project.codes" />
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn
                          @click="hideDialog(index)"
                          :color="currentColor"
                          class="white--text"
                          >Fermer</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="justify-center">
                  <h3>Autres projets</h3>
                </v-card-title>
                <v-card-subtitle class="text-center">
                  Mes autres projets
                </v-card-subtitle>
                <v-card-text>
                  <v-chip-group color="orange">
                    <v-chip
                      @click="filter(key)"
                      v-for="(val, key) in option.getFilterData"
                      :key="key"
                    >
                      {{ key }}
                    </v-chip>
                  </v-chip-group>
                  <Isotope
                    ref="cpt"
                    :list="otherProjects"
                    :options="option"
                    @filter="filterOption = arguments[0]"
                    v-images-loaded:on.progress="layout"
                  >
                    <v-card
                      v-for="project in otherProjects"
                      :key="project.title"
                      width="200"
                      class="ma-3"
                    >
                      <v-card-title>
                        <a :href="project.link" target="_blank" rel="noopener">
                          {{ project.title }}</a
                        >
                      </v-card-title>
                      <v-card-subtitle>{{ project.subtitle }}</v-card-subtitle>
                    </v-card>
                  </Isotope>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Isotope from "vueisotope";
import imagesLoaded from "vue-images-loaded";
import Terminal from "./PartProjects/Terminal.vue";

export default {
  components: { Isotope, Terminal },
  directives: {
    imagesLoaded,
  },
  data: () => ({
    tab: 4,
    dialog: [],
    showStudy: false,
    colors: ["green", "blue", "red", "purple", "orange"],
    mainProjects: [
      {
        title: "AnaelBonnafous",
        subtitle: "Mon portfolio personnel en ligne",
        color: "green",
        available: true,
        tools: [
          {
            name: "Vue.js",
            icon: "vue.png",
            color: "green",
            detail:
              "Framework Javascript léger, évolutif et open-source pour la construction d'interfaces utilisateur réactives. Il se veut comme une combinaison du meilleur de React et d'Angular.",
          },
          {
            name: "Vuetify",
            icon: "vuetify.png",
            color: "blue",
            detail:
              "Framework Vue.js d'interface pour l'ajout de composants personnalisables respectant les règles du Material design.",
          },
        ],
        description:
          "Il est ma carte d'identité en ligne, toutes mes informations concernant le développement web / mobile y sont renseignées. Il s'agit d'une version évoluée de mon CV avec beaucoup plus de détails et d'interactivité.",
        details: [
          "Une courte présentation et mon CV au format pdf",
          "La chronologie de mes études et expériences professionnelles",
          "Des détails sur mes compétences techniques en développement",
          "Une liste de mes projets personnels en cours de réalisation",
          "Un formulaire de contact relié à ma boîte mail",
          "...",
        ],
        codes: [
          "<span class='green--text'>vue</span> create anaelbonnafous",
          "cd anaelbonnafous",
          "<span class='green--text'>vue</span> add <span class='blue--text'>vuetify</span>",
        ],
      },
      {
        title: "CodeSnippet",
        subtitle: "Une plateforme d'apprentissage du code",
        color: "blue",
        available: true,
        tools: [
          {
            name: "Laravel",
            icon: "laravel.png",
            color: "red",
            detail:
              "Framework PHP à la syntaxe expressive et élégante permettant de créer toute sorte de sites web selon le principe modèle-vue-contrôleur (MVC) et proposant un large écosystème.",
          },
          {
            name: "Vue.js",
            icon: "vue.png",
            color: "green",
            detail:
              "Framework Javascript léger, évolutif et open-source pour la construction d'interfaces utilisateur réactives. Il se veut comme une combinaison du meilleur de React et d'Angular.",
          },
        ],
        description:
          "C'est un nouvel outil pédagogique et collaboratif pour apprendre les bases du développement web. En créant un projet Snippet, vous pouvez travailler sur du HTML, CSS et JavaScript avec vos professeurs, vos amis et d'autres étudiants.",
        details: [
          "Un éditeur de code en temps réel avec coloration syntaxique",
          "Un mode enseignant pour regrouper des élèves et organiser des TP",
          "Un mode examen avec un compte à rebours, utile pour les projets notés",
          "Un système d'invitation aux projets avec envoi d'emails",
          "...",
        ],
        codes: [
          "composer create-project <span class='red--text'>laravel/laravel</span> codesnippet-back --prefer-dist",
          "<span class='green--text'>vue</span> create codesnippet-front",
        ],
      },
      {
        title: "CyberMind",
        subtitle: "Un site concours de CTF et d'énigmes web",
        color: "red",
        available: true,
        tools: [
          {
            name: "Laravel",
            icon: "laravel.png",
            color: "red",
            detail:
              "Framework PHP à la syntaxe expressive et élégante permettant de créer toute sorte de sites web selon le principe modèle-vue-contrôleur (MVC) et proposant un large écosystème.",
          },
        ],
        description:
          "C'est un projet événementiel de CTF (Capture The Flag) basé sur la cybersécurité dont le but principal est la découverte et la sensibilisation au monde de la sécurité informatique ainsi que du web en général à travers un mini concours.",
        details: [
          "Un nouveau concours tous les ans avec à chaque fois une histoire originale",
          "De nombreuses épreuves CTF et énigmes web avec une difficulté croissante",
          "Un système de classement par rapport au temps et aux indices utilisés",
          "...",
        ],
        codes: [
          "composer create-project <span class='red--text'>laravel/laravel</span> cybermind --prefer-dist",
          "cd cybermind",
          "npm install bootstrap jquery",
        ],
      },
      {
        title: "Dewire",
        subtitle: "Une plateforme saas de gestion de projets web",
        color: "purple",
        available: false,
        tools: [
          {
            name: "Laravel",
            icon: "laravel.png",
            color: "red",
            detail:
              "Framework PHP à la syntaxe expressive et élégante permettant de créer toute sorte de sites web selon le principe modèle-vue-contrôleur (MVC) et proposant un large écosystème.",
          },
          {
            name: "Inertia",
            icon: "inertia.png",
            color: "purple",
            detail:
              "Outil permettant de combiner un framework côté serveur avec un autre côté client pour réaliser des applications web monopage (SPA) avec un rendu côté serveur.",
          },
        ],
        description:
          "C'est une plateforme de gestion de projets en équipe intégrant de nombreux modules visant à remplacer les outils informatique du quotidien en les simplifiant et en les rassemblant (tâches, notes, messages, dessins, stockage, ...)",
        details: [
          "Un dashboard complet permettant de suivre vos équipes et vos projets",
          "Des modules indépendants et complets basés sur des technologies open-source",
          "Des fonctionnalités poussées comme le temps réel, la gestion de rôle et d'équipes",
          "...",
        ],
        codes: [
          "composer create-project <span class='red--text'>laravel/laravel</span> dewire --prefer-dist",
          "cd dewire",
          "composer require <span class='purple--text text--lighten-2'>inertiajs/inertia</span>-<span class='red--text'>laravel</span>",
          "npm install @<span class='purple--text text--lighten-2'>inertiajs</span>/<span class='green--text'>vue3</span>",
        ],
      },
      // {
      //   title: "ArtyomUrbex",
      //   subtitle: "Mon site web pour publier mes photos d'urbex",
      //   color: "red",
      //   available: false,
      //   tools: [
      //     {
      //       name: "Laravel",
      //       icon: "laravel.png",
      //       color: "red",
      //       detail:
      //         "Framework PHP à la syntaxe expressive et élégante permettant de créer toute sorte de sites web selon le principe modèle-vue-contrôleur (MVC) et proposant un large écosystème.",
      //     },
      //     {
      //       name: "Inertia",
      //       icon: "inertia.png",
      //       color: "purple",
      //       detail:
      //         "Outil permettant de combiner un framework côté serveur avec un autre côté client pour réaliser des applications web monopage (SPA) avec un rendu côté serveur.",
      //     },
      //   ],
      //   description:
      //     "Il est mon espace de partage de ma passion, j'y expose mes aventures et photographies. Tout le monde peut y retrouver les endroits abandonnés que j'ai exploré ces dernières années et échanger dans le respect des règles de l'urbex.",
      //   details: [
      //     "Un système d'authentification et de comptes administrateurs",
      //     "La liste de toutes mes explorations triées par pays, région et catégorie",
      //     "De nombreuses photos et une courte histoire sur chaque endroit visité",
      //     "Un espace commentaire sous chaque article permettant de discuter",
      //     "...",
      //   ],
      //   codes: [
      //     "composer create-project <span class='red--text'>laravel/laravel</span> artyomurbex --prefer-dist",
      //     "cd artyomurbex",
      //     "composer require <span class='purple--text text--lighten-2'>inertiajs/inertia</span>-<span class='red--text'>laravel</span>",
      //     "npm install @<span class='purple--text text--lighten-2'>inertiajs/inertia</span> @<span class='purple--text text--lighten-2'>inertiajs/inertia</span>-<span class='green--text'>vue</span>",
      //   ],
      // },
      // {
      //   title: "D6",
      //   subtitle:
      //     "Une application mobile pour référencer ses lieux abandonnés explorés",
      //   color: "blue",
      //   available: false,
      //   tools: [
      //     {
      //       name: "Flutter",
      //       icon: "flutter.png",
      //       color: "blue",
      //       detail:
      //         "Framework mobile complet permettant de développer des applications mobiles, web et desktop à partir d'une seule base de code et en utilisant des milliers de Widgets.",
      //     },
      //   ],
      //   description:
      //     "Il est mon compagnon de route pendant mes excursions, il me permet de savoir rapidement quels sont les endroits abandonnés à proximité et leur état actuel. Il fonctionne de pair avec mon site web artyomurbex.fr afin d'avoir des données à jour et est pour l'instant réservé à mon usage personnel.",
      //   details: [
      //     "Une carte interactive avec vue satellite et géolocalisation",
      //     "De nombreuses possibilités de marqueurs pour tous les types de spot",
      //     "La possibilité de laisser des notes sur chaque endroit afin d'avoir des données à jour",
      //     "...",
      //   ],
      //   codes: [
      //     "<span class='blue--text'>flutter</span> doctor -v",
      //     "<span class='blue--text'>flutter</span> create d6",
      //   ],
      // },
      // {
      //   title: "Facilists",
      //   subtitle: "Une web app permettant de gérer des listes de tout type",
      //   color: "orange",
      //   available: false,
      //   tools: [
      //     {
      //       name: "Firebase",
      //       icon: "firebase.png",
      //       color: "orange",
      //       detail:
      //         "Ensemble de services cloud comprenant la création de base de données NoSQL, l'authentification des utilisateurs et l'hébergement d'applications.",
      //     },
      //     {
      //       name: "Nuxt.js",
      //       icon: "nuxt.png",
      //       color: "green",
      //       detail:
      //         "Framework Vue.js simplifiant grandement le développement en gérant automatiquement le routage, en intégrant de nombreux modules et en optimisant la SEO.",
      //     },
      //   ],
      //   description:
      //     "Il est un projet qui m'est venu à l'idée afin d'aider certains de mes amis et proches à gérer toutes sortes de listes. Il peut aussi bien s'agir de tâches ménagères que de travaux à réaliser en passant par des choses à acheter.",
      //   details: [
      //     "Un dashboard complet sur l'état actuel de vos listes",
      //     "De larges possibilités pour gérer vos listes et leur contenu",
      //     "Des performances très élevées et une navigation fluide",
      //     "...",
      //   ],
      //   codes: [
      //     "npx create-<span class='green--text'>nuxt</span>-app facilists",
      //     "cd facilists",
      //     "npm install <span class='orange--text'>firebase</span> @<span class='green--text'>nuxtjs</span>/<span class='orange--text'>firebase</span>",
      //   ],
      // },
    ],
    otherProjects: [
      {
        link: "http://htmlcss.anaelbonnafous.fr",
        title: "TravelAgency",
        subtitle: "Landing page d'une agence de voyage",
        tools: ["HTML", "CSS"],
      },
      {
        link: "http://js.anaelbonnafous.fr",
        title: "Snake",
        subtitle: "Jeu en ligne snake pour deux joueurs",
        tools: ["Javascript", "HTML"],
      },
      {
        link: "http://jquery.anaelbonnafous.fr",
        title: "TopActrices",
        subtitle: "Page web interactive animée",
        tools: ["jQuery", "Javascript", "CSS"],
      },
      {
        link: "http://bootstrap.anaelbonnafous.fr",
        title: "Portfolio",
        subtitle: "CV en ligne responsive",
        tools: ["Bootstrap", "PHP", "HTML", "CSS"],
      },
      {
        link: "http://php.anaelbonnafous.fr",
        title: "BurgerCode",
        subtitle: "Site web dynamique avec panel admin",
        tools: ["PHP", "MySQL", "Bootstrap", "HTML", "CSS"],
      },
      {
        link: "http://wordpress.anaelbonnafous.fr",
        title: "Blog de John Codeur",
        subtitle: "Blog et site de e-commerce",
        tools: ["Wordpress"],
      },
    ],

    tools: [
      "HTML",
      "CSS",
      "Javascript",
      "jQuery",
      "Bootstrap",
      "PHP",
      "MySQL",
      "Wordpress",
    ],

    filterOption: "show all",
    option: {
      itemSelector: ".element-item",
      getFilterData: {
        Tout() {
          return true;
        },
        HTML(el) {
          return el.tools.indexOf("HTML") > -1;
        },
        CSS(el) {
          return el.tools.indexOf("CSS") > -1;
        },
        Javascript(el) {
          return el.tools.indexOf("Javascript") > -1;
        },
        jQuery(el) {
          return el.tools.indexOf("jQuery") > -1;
        },
        Bootstrap(el) {
          return el.tools.indexOf("Bootstrap") > -1;
        },
        PHP(el) {
          return el.tools.indexOf("PHP") > -1;
        },
        MySQL(el) {
          return el.tools.indexOf("MySQL") > -1;
        },
        Wordpress(el) {
          return el.tools.indexOf("Wordpress") > -1;
        },
      },
    },
  }),
  computed: {
    currentColor() {
      return this.colors[this.tab];
    },
  },
  methods: {
    filter(key) {
      this.$refs.cpt.filter(key);
    },
    hideDialog(index) {
      this.dialog.splice(index, 1);
    },
  },
};
</script>
<template>
  <v-card color="black">
    <v-card-actions class="white">
      <span>
        <v-icon color="black">mdi-powershell</v-icon>
        <span class="d-none d-md-inline">Invite de commandes</span>
      </span>
      <v-spacer></v-spacer>
      <v-btn tile icon plain aria-label="minimize">
        <v-icon color="black">mdi-minus</v-icon>
      </v-btn>
      <v-btn tile icon plain aria-label="fullscreen">
        <v-icon color="black">mdi-square-outline</v-icon>
      </v-btn>
      <v-btn tile icon plain color="red" aria-label="close">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
    <div v-for="(code, key) in codes" :key="key">
      <kbd v-html="'C:\\Users\\Anaël> ' + code" class="transparent"></kbd>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    codes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
<template>
  <v-app>
    <AppBar />

    <NavigationDrawer />

    <Landing />

    <v-main style="overflow-x: hidden">
      <v-container fluid>
        <PartPresentation />
        <PartExperiences />
        <PartSkills />
        <PartProjects />
        <PartContact />
      </v-container>
      <Footer />
    </v-main>

    <FloatingActionButton />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import NavigationDrawer from "./components/NavigationDrawer.vue";
import Landing from "./components/Landing.vue";
import FloatingActionButton from "./components/FloatingActionButton.vue";
import PartPresentation from "./components/PartPresentation.vue";
import PartExperiences from "./components/PartExperiences.vue";
import PartSkills from "./components/PartSkills.vue";
import PartProjects from "./components/PartProjects.vue";
import PartContact from "./components/PartContact.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    AppBar,
    NavigationDrawer,
    Landing,
    Footer,
    FloatingActionButton,
    PartPresentation,
    PartExperiences,
    PartSkills,
    PartProjects,
    PartContact,
  },
};
</script>
<template>
  <v-sheet color="blue" class="pa-1 my-14 rounded">
    <v-card class="py-2" elevation="5" id="skills">
      <div class="text-center pb-5">
        <h2 class="font-weight-bold mb-3">
          <v-icon x-large color="blue">mdi-star</v-icon>
          Compétences techniques
        </h2>
        <p class="subheading">
          Détails sur mes compétences techniques en développement
        </p>
      </div>
      <v-row class="mx-1">
        <v-col
          v-for="(category, key) in categories"
          :key="key"
          cols="12"
          md="6"
          :data-aos="category.animation"
        >
          <h3 class="headline font-weight-bold">
            {{ category.title }}
          </h3>
          <v-list>
            <v-list-item v-for="(tool, key) in category.tools" :key="key">
              <v-list-item-avatar rounded="0">
                <v-img
                  :src="require(`../assets/icons/${tool.icon}`)"
                  :alt="`Le logo de ${tool.name}`"
                  contain
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ tool.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-weight-light">
                {{ progressLevel(tool.progress) }}
                <v-progress-linear
                  :value="tool.progress"
                  buffer-value="0"
                  stream
                  :color="tool.color"
                  height="10"
                  rounded
                  :aria-label="tool.name"
                ></v-progress-linear>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  methods: {
    progressLevel(progress) {
      switch (true) {
        case progress <= 60:
          return "Débutant";
        case progress <= 70:
          return "Intermédiaire";
        case progress <= 80:
          return "Avancé";
        default:
          return "Expert";
      }
    },
  },
  data() {
    return {
      categories: [
        {
          title: "",
          animation: "fade-down-right",
          tools: [
            {
              name: "Laravel",
              icon: "laravel.png",
              color: "#f44336",
              progress: 80,
            },
            {
              name: "Vue",
              icon: "vue.png",
              color: "#4caf50",
              progress: 80,
            },
            {
              name: "Ionic",
              icon: "ionic.png",
              color: "#2196f3",
              progress: 60,
            },
          ],
        },
        {
          title: "",
          animation: "fade-down-left",
          tools: [
            {
              name: "Tailwind",
              icon: "tailwind.png",
              color: "#2196f3",
              progress: 80,
            },
            {
              name: "Sass",
              icon: "sass.png",
              color: "#9c27b0",
              progress: 60,
            },
            {
              name: "MySQL",
              icon: "mysql.png",
              color: "#ff9800",
              progress: 70,
            },
          ],
        },
        {
          title: "",
          animation: "fade-up-right",
          tools: [
            {
              name: "Inertia",
              icon: "inertia.png",
              color: "#9c27b0",
              progress: 70,
            },
            {
              name: "Nuxt",
              icon: "nuxt.png",
              color: "#4caf50",
              progress: 70,
            },
            {
              name: "Livewire",
              icon: "livewire.png",
              color: "#9c27b0",
              progress: 60,
            },
          ],
        },
        {
          title: "",
          animation: "fade-up-left",
          tools: [
            {
              name: "Git",
              icon: "git.png",
              color: "#f44336",
              progress: 80,
            },
            {
              name: "Linux",
              icon: "linux.png",
              color: "#ff9800",
              progress: 70,
            },
            {
              name: "Docker",
              icon: "docker.png",
              color: "#2196f3",
              progress: 60,
            },
          ],
        },
      ],
    };
  },
};
</script>
<template>
  <v-tooltip :color="bubbleData.color" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        class="bouncingButtons"
        @click="$vuetify.goTo(bubbleData.link)"
        @mouseover="changeColor"
        data-aos-anchor="#landing"
        :data-aos="bubbleData.animation.type"
        :data-aos-delay="bubbleData.animation.delay"
        data-aos-duration="1000"
        :color="bubbleData.color"
        x-large
        dark
        fab
        :aria-label="bubbleData.text"
        ><v-icon>{{ bubbleData.icon }}</v-icon></v-btn
      >
    </template>
    <span>{{ bubbleData.text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    bubbleData: Object,
  },
  methods: {
    changeColor() {
      this.$emit("update:color", this.bubbleData.color);
    },
  },
};
</script>
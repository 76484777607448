<template>
  <v-sheet id="landing" color="white" class="text-center elevation-10">
    <v-container fluid fill-height>
      <v-row class="justify-center">
        <v-sheet
          color="green"
          class="pa-1"
          rounded
          data-aos="zoom-out"
          data-aos-duration="1000"
        >
          <v-card
            @mouseover="color = '#4caf50'"
            @click="$vuetify.goTo('#presentation')"
            elevation="10"
            link
          >
            <v-row align="center" justify="center">
              <v-img
                class="col-12 col-sm-4"
                max-height="150"
                max-width="100"
                :src="require('/public/logo.webp')"
                alt="Le logo de mon portfolio"
              ></v-img>
              <v-card-text class="col-12 col-sm-8">
                <v-card-title class="justify-center">
                  <h1 class="display-1 font-weight-thin">Anaël Bonnafous</h1>
                </v-card-title>
                <v-card-subtitle class="text-center"
                  >Développeur web / mobile</v-card-subtitle
                >
                <v-tooltip color="green" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="presentationButton"
                      @click="$vuetify.goTo('#presentation')"
                      v-bind="attrs"
                      v-on="on"
                      data-aos="fade"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                      fab
                      x-large
                      dark
                      color="green"
                      aria-label="Présentation"
                      ><v-icon>mdi-account</v-icon></v-btn
                    >
                  </template>
                  <span>Présentation</span>
                </v-tooltip>
              </v-card-text>
            </v-row>
          </v-card>
        </v-sheet>
      </v-row>
      <v-row align="center" justify="center">
        <v-col
          v-for="(bubbleLink, key) in bubbleLinks"
          :key="key"
          cols="6"
          sm="2"
        >
          <BubbleLink :bubbleData="bubbleLink" @update:color="color = $event" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import BubbleLink from "./Landing/BubbleLink.vue";

export default {
  components: { BubbleLink },
  data() {
    return {
      color: "black",
      bubbleLinks: [
        {
          text: "Expériences",
          icon: "mdi-briefcase",
          link: "#experiences",
          color: "#f44336",
          animation: {
            type: "fade-up-right",
            delay: 400,
          },
        },
        {
          text: "Compétences",
          icon: "mdi-star",
          link: "#skills",
          color: "#2196f3",
          animation: {
            type: "fade-up",
            delay: 600,
          },
        },
        {
          text: "Projets",
          icon: "mdi-lightbulb",
          link: "#projects",
          color: "#ff9800",
          animation: {
            type: "fade-up",
            delay: 800,
          },
        },
        {
          text: "Contact",
          icon: "mdi-email",
          link: "#contact",
          color: "#9c27b0",
          animation: {
            type: "fade-up-left",
            delay: 1000,
          },
        },
      ],
    };
  },
  mounted() {
    const landing = document.getElementById("landing");
    landing.addEventListener(
      "mousemove",
      (e) => {
        [1, 0.8, 0.6, 0.4, 0.2].forEach((i) => {
          var j = (1 - i) * 50;
          var elem = document.createElement("span");
          var size = Math.ceil(Math.random() * 20 * i) + "px";
          elem.style.position = "fixed";
          elem.style.top =
            e.pageY + Math.round(Math.random() * j - j / 2) - 10 + "px";
          elem.style.left =
            e.pageX + Math.round(Math.random() * j - j / 2) - 10 + "px";
          elem.style.position = "absolute";
          elem.style.width = size;
          elem.style.height = size;
          elem.style.borderRadius = size;
          elem.style.background = this.color;
          elem.style.pointerEvents = "none";
          elem.style.zIndex = 1;
          landing.appendChild(elem);
          window.setTimeout(function () {
            landing.removeChild(elem);
          }, Math.round(Math.random() * i * 500));
        });
      },
      false
    );
  },
};
</script>

<style>
#landing {
  height: 100vh !important;
  z-index: 1;
}

#landing:hover {
  cursor: none;
}

.v-card__text,
.v-card__title {
  word-break: normal !important;
}

#presentationButton {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

.bouncingButtons {
  z-index: 2;
}

.bouncingButtons:hover {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
  }
}
@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY(10px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
</style>
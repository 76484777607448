<template>
  <v-sheet color="purple" class="mt-14 pa-1" rounded="">
    <v-card class="py-2" elevation="5" id="contact">
      <div class="text-center pb-5">
        <h2 class="font-weight-bold mb-3">
          <v-icon x-large color="purple">mdi-email</v-icon>
          Formulaire de contact
        </h2>
        <p class="subheading">Me contacter directement</p>
      </div>
      <v-container data-aos="fade-up">
        <v-form
          @submit.prevent="sendEmail"
          v-model="valid"
          lazy-validation
          v-if="!alreadySend"
        >
          <v-row justify="center" class="text-center">
            <v-col cols="10">
              <v-text-field
                name="name"
                v-model="name"
                required
                :error-messages="nameErrors"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                label="Nom*"
                color="purple"
                outlined
              ></v-text-field>
              <v-text-field
                name="email"
                v-model="email"
                required
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                label="E-mail*"
                color="purple"
                outlined
              ></v-text-field>
              <v-text-field
                name="subject"
                v-model="subject"
                required
                :error-messages="subjectErrors"
                @input="$v.subject.$touch()"
                @blur="$v.subject.$touch()"
                label="Sujet*"
                color="purple"
                outlined
              ></v-text-field>
              <v-textarea
                name="message"
                v-model="message"
                required
                :error-messages="messageErrors"
                @input="$v.message.$touch()"
                @blur="$v.message.$touch()"
                label="Message*"
                color="purple"
                outlined
                no-resize
                height="142"
              ></v-textarea>
              <v-btn type="submit" :disabled="!valid" color="success" large
                >Envoyer<v-icon class="ml-2">mdi-send</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <v-container v-else fill-height class="justify-center">
          <div class="purple--text text-center">
            <v-icon color="purple">mdi-emoticon</v-icon>
            Merci pour votre message, j'y répondrai au plus vite !
          </div>
        </v-container>
      </v-container>
      <v-snackbar
        app
        v-model="snackbar.visible"
        :color="snackbar.type"
        :timeout="3000"
        ><v-icon class="mr-2">{{ snackbar.icon }}</v-icon
        >{{ snackbar.text }}</v-snackbar
      >
    </v-card>
  </v-sheet>
</template>

<script>
import emailjs from "emailjs-com";
import { required, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    valid: true,
    alreadySend: null,
    name: "",
    email: "",
    subject: "",
    message: "",
    snackbar: {
      visible: false,
      text: "",
      icon: "",
      type: "",
    },
  }),
  validations: {
    name: { required },
    email: { required, email },
    subject: { required },
    message: { required },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Le champ Nom est obligatoire");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Le champ E-mail doit être valide");
      !this.$v.email.required && errors.push("Le champ E-mail est obligatoire");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      !this.$v.subject.required &&
        errors.push("Le champ Sujet est obligatoire");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push("Le champ Message est obligatoire");
      return errors;
    },
  },
  created() {
    this.alreadySend = localStorage.getItem("alreadySend");
  },
  methods: {
    sendEmail(e) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          emailjs
            .sendForm(
              "service_phzlst8",
              "template_cgjdkan",
              e.target,
              "user_dSLn3d1AQeskbPssvVzyV"
            )
            .then(
              () => {
                this.showValidSnackbar(true);
              },
              () => {
                this.showValidSnackbar(false);
              }
            );
        } catch (error) {
          this.showValidSnackbar(false);
        }
      }
    },
    showValidSnackbar(valid) {
      if (valid) {
        this.snackbar.text = "Mail envoyé avec succès";
        this.snackbar.type = "success";
        this.snackbar.icon = "mdi-check";

        this.$v.$reset();
        this.name = "";
        this.email = "";
        this.subject = "";
        this.message = "";

        this.alreadySend = true;
        localStorage.setItem("alreadySend", this.alreadySend);
      } else {
        this.snackbar.text = "Erreur lors de l'envoi du mail";
        this.snackbar.type = "error";
        this.snackbar.icon = "mdi-alert-circle-outline";
      }
      this.snackbar.visible = true;
    },
  },
};
</script>

<style>
</style>
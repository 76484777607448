<template>
  <div class="my-14 py-2" id="experiences">
    <div class="text-center pb-5">
      <h2 class="font-weight-bold mb-3">
        <v-icon x-large color="red">mdi-briefcase</v-icon>
        Expériences professionnelles
      </h2>
      <p class="subheading">Chronologie des mes études et expériences professionnelles</p>
    </div>

    <v-row justify="center" class="mx-1">
      <v-sheet
        outlined
        color="green"
        class="pa-1 col-12 col-md-8 rounded"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <v-card>
          <v-timeline :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item @click.native="showStudy = !showStudy" :icon="showStudy ? 'mdi-close' : 'mdi-school'" icon-color="green" color="white">
              <template v-slot:opposite>
                <v-icon class="pa-0 ma-0" color="green">mdi-chevron-right</v-icon>
              </template>
              <v-container class="pl-0">
                <v-icon color="green">mdi-chevron-left</v-icon>
              </v-container>
            </v-timeline-item>
            <v-alert class="mb-0 transparent" :value="showStudy" transition="slide-y-transition">
              <v-timeline-item small :icon="$vuetify.breakpoint.smAndDown ? 'mdi-chevron-right' : index % 2 ? 'mdi-chevron-right' : 'mdi-chevron-left'" color="green" v-for="(study, index) in studies" :key="index">
                <template v-slot:opposite>
                  <span class="headline font-weight-bold green--text">
                  {{ study.date }}
                  </span>
                </template>
                <div>
                  <h3 class="text-h6 font-weight-bold">{{ study.title }}</h3>
                  <p class="subtitle-2 font-italic">
                    {{ study.place }}
                  </p>
                </div>
              </v-timeline-item>
            </v-alert>
          </v-timeline>
        </v-card>
      </v-sheet>
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(experience, index) in experiences"
          :key="index"
          :color="experience.color"
          :icon="$vuetify.breakpoint.smAndDown ? 'mdi-chevron-right' : index % 2 ? 'mdi-chevron-left' : 'mdi-chevron-right'"
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${experience.color}--text`">
              {{ experience.year }}</span>
          </template>
          <div
            class="py-4"
            :data-aos="index % 2 ? 'fade-right' : 'fade-left'"
            data-aos-duration="1000"
          >
            <h3 :class="`headline ${experience.color}--text`">
              {{ experience.title }}
            </h3>
            <p class="subtitle-1 font-weight-light font-italic">
              {{ experience.place }}
              <br />
              {{ experience.date }}
            </p>
            <p class="mt-4 d-none d-md-block">
              {{ experience.text }}
              <ul class="pt-2">
                <li v-for="(detail, key) in experience.details" :key="key">
                  {{ detail }}
                </li>
              </ul>
            </p>
            <v-dialog v-model="dialog[index]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="d-block d-md-none"
                  v-bind="attrs"
                  v-on="on"
                  text
                  plain
                  :color="experience.color"
                  >Plus d'infos...</v-btn
                >
              </template>
              <v-card class="py-5">
                <v-card-subtitle
                  :class="`headline font-weight-light ${experience.color}--text`"
                >
                  {{ experience.title }}
                </v-card-subtitle>
                <v-card-subtitle class="font-weight-light font-italic">
                  {{ experience.place }}
                  <br />
                  {{ experience.date }}
                </v-card-subtitle>
                <v-card-text>
                  {{ experience.text }}
                  <ul>
                    <li v-for="(detail, key) in experience.details" :key="key">
                      {{ detail }}
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="hideDialog(index)"
                    :color="experience.color"
                    class="white--text"
                    >Fermer</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: [],
    showStudy: false,
    studies: [
      {
        title: "BAC S SVT spécalité",
        place: "Lycée Edouard Belin, 70000 Vesoul",
        date: "2015 - 2018",
      },
      {
        title: "BTS SIO option SLAM",
        place: "Lycée Louis Pergaud, 25000 Besançon",
        date: "2018 - 2020",
      },
      {
        title: "LP AMIO en alternance",
        place: "IUT SD, 88100 Saint-Dié-des-Vosges",
        date: "2020 - 2021",
      },
      {
        title: "Mastère Expert Développement Web en alternance",
        place: "Lyon Ynov Campus, 69007 Lyon",
        date: "2021 - 2023",
      },
    ],
    experiences: [
      {
        year: "2019",
        color: "red",
        title: "Développeur logiciel en stage",
        place: "Stallantis, 70000 Vesoul",
        date: "3 Juin 2019 - 28 Juin 2019 (1 mois)",
        text: "Développement d'un logiciel de gestion des clients des magasins de ventes de pièces détachées et de leurs colis pour le service qualité client de l'entreprise",
        details: [
          "Interface utilisateur responsive",
          "Mise à jour des clients par fichier .txt",
          "Gestion d'adresses mail à informer",
          "Améliorations à la suite de retours",
        ],
      },
      {
        year: "2020",
        color: "blue",
        title: "Développeur logiciel en stage",
        place: "Stellantis, 70000 Vesoul",
        date: "27 Janvier 2020 - 22 Mars 2020 (2 mois)",
        text: "Développement d'un logiciel de gestion de stock et de pièces détachées, basé sur un programme obsolète ne répondant plus aux exigences d'évolution du site",
        details: [
          "Interface utilisateur responsive",
          "Gestion de comptes utilisateurs",
          "Recherche d'informations sur une pièce",
          "Exportation au format .xls",
        ],
      },
      {
        year: "2020 - 2021",
        color: "orange",
        title: "Développeur applications web et logiciel en alternance",
        place: "Stellantis, 70000 Vesoul",
        date: "1er septembre 2020 - 29 août 2021 (1 an)",
        text: "Développement d'une application desktop / mobile de gestion des dossiers de refus pour les pièces détachées renvoyées afin d'automatiser le processus et réduire les dossiers papiers",
        details: [
          "Interface utilisateur responsive",
          "Prise de photos avec l'appareil",
          "Génération d'un document pdf",
          "Envoi d'un mail au client",
        ],
      },
      {
        year: "2021 - 2023",
        color: "purple",
        title: "Développeur applications web et mobiles en alternance",
        place: "Netizis, 70000 Navenne",
        date: "septembre 2021 - septembre 2023 (2 ans)",
        text: "Migration de sites e-commerce pour un vendeur d'équipements moto en osCommerce, amélioration de la sécurité, du design et du référencement. Et d'autres missions comme :",
        details: [
          "Développement d'une application web éducative en Laravel, Vue et Inertia",
          "Participation à la réalisation d'un site e-commerce avec Sylius",
          "Mise en place d'un ERP interne avec l'outil Odoo",
          "Maintenance de sites Joomla et Prestashop",
        ],
      },
    ],
  }),
  methods: {
    hideDialog(index) {
      this.dialog.splice(index, 1);
    },
  },
};
</script>
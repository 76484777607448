<template>
  <v-footer color="white" class="justify-center">
    <v-card flat class="text-center transparent">
      <div>
        <v-btn
          x-large
          icon
          target="_blank"
          href="https://discordapp.com/users/607890559406964746"
          rel="noopener"
          aria-label="Discord"
          ><v-icon color="#7289da">mdi-discord</v-icon></v-btn
        >
        <v-btn
          x-large
          icon
          target="_blank"
          href="https://github.com/AnaelBonnafous/"
          rel="noopener"
          aria-label="Github"
          ><v-icon color="#000000">mdi-github</v-icon></v-btn
        >
        <v-btn
          x-large
          icon
          target="_blank"
          href="https://www.linkedin.com/in/anaelbonnafous/"
          rel="noopener"
          aria-label="Linkedin"
          ><v-icon color="#0a66c2">mdi-linkedin</v-icon></v-btn
        >
      </div>

      {{ copyright }}
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    copyright() {
      let year = new Date().getFullYear();
      return "© " + year + " - Anaël Bonnafous - Tous droits réservés";
    },
  },
};
</script>